// Custom spacing with 'px' units
@import "../../core/td_variables";

// remove 'xl' breakpoint, we don't use it so no need to generate classes for it
$grid-breakpoints: map-remove($grid-breakpoints, xl);

@import "td_variables";

// --- Bootstrap external dependency START ---------------------------------------------//
// Required
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/utilities/spacing";
// --- Bootstrap external dependency END -----------------------------------------------//
